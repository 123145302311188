import React, { Component } from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
// import Store from "./Store.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import Store from "./_helpers/store";
import * as ACTIONS from "./actions/authActions";
import { Provider } from "react-redux";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import ReactGA from "react-ga";
import Modal from 'react-modal'; // Import the Modal component
ReactGA.initialize("AW-10947237489/Xs42COSlztgDEPGshuQo");

// import "datatables.net-dt/css/jquery.dataTables.min.css"
let adminPath = window.location.pathname ? window.location.pathname : "";
adminPath = adminPath
  ? adminPath.split("/")[1]
    ? adminPath.split("/")[1]
    : ""
  : "";

if (adminPath !== "admin") {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    Store.dispatch(ACTIONS.user_remain_login());
    Store.dispatch(ACTIONS.admin_screens(JSON.parse(localStorage.getItem("dealerSccrens"))));
  }
}

if (adminPath === "admin") {
  const admin = JSON.parse(localStorage.getItem("admin"));
  if (admin) {
    Store.dispatch(ACTIONS.admin_remain_login());
    Store.dispatch(ACTIONS.admin_screens(JSON.parse(localStorage.getItem("adminSccrens"))));
  }
}

class App extends Component {
  componentDidMount() {
    window.FT_ENV = process.env.REACT_APP_ENV;
  }

  render() {
    return (
      <Provider store={Store}>
        <Routes />
      </Provider>
    );
  }
}

Modal.setAppElement('#app');
ReactDOM.render(<App />, document.getElementById("app"));
