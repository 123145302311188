// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAOhQ0zZxB4321Fkl1IJvqlk6kz0c00TWA',
  authDomain: 'notes-api-689d4.firebaseapp.com',
  databaseURL: 'https://notes-api-689d4-default-rtdb.firebaseio.com',
  projectId: 'notes-api-689d4',
  storageBucket: 'notes-api-689d4.appspot.com',
  messagingSenderId: '821575829720',
  appId: '1:821575829720:web:b3da8134586f528023efad',
};

// Initialize Firebase
const initFirebase = () => {
  console.log('firebase init');
  return initializeApp(firebaseConfig);
};
export const firebaseApp = initFirebase();
export const fireStoreDB = getFirestore(firebaseApp);
